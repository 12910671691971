/* eslint-disable react-hooks/exhaustive-deps */
import { captureException } from '@sentry/core';
import dayjs from 'dayjs';
import firebase from 'firebase';
import { AnimatePresence } from 'framer-motion';
import _, { now } from 'lodash';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Slide, toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';
import { useAppDispatch, useAppSelector, useFirebase } from 'src/app/hooks';
import {
  globalReducers,
  selectGlobalAuth,
  selectGlobalConfig,
  selectGlobalInfo,
} from 'src/features/@globalSlices/globalSlice';

import { InformationModal } from '../../information/component/InformationModal';
import QNAModal from '../../qna/component/QNAModal';
import { QnaResultModal } from '../../qna/component/QnaResultModal';
import {
  addNotification,
  getReplyTriggerMessageTemplate,
  uploadQna,
} from '../../qna/service/QNAService';
import initialState from '../../qna/slice/QNAInitialData';
import {
  isQNAShow,
  setQNAInfoList,
  setQNAShow,
} from '../../qna/slice/QNASlice';
import { downloadURL } from '../../util/downloadURL';
import { addSuffix } from '../../util/nameFormatter';
import { randomString } from '../../util/randomString';
import {
  checkIsAppInstalled,
  getMobileOS,
  isInAppBrowser,
  isIOSInstalled,
} from '../../util/userAgentChecker';
import {
  addDescriptionBotMessage,
  addNextActionBotMessage,
  addNextActionIsHelpfulBotMessage,
  addNoAnswerBotMessage,
  addRecommendContentsMessage,
  addRecommendGuideMessage,
  addTriggerMessage,
  createPetData,
  getLastEvent,
  getQNAType,
  getRecommendContents,
  isActiveChatBotTime,
  isFullFilledPetData,
  updatePetData,
  updatePetDataOnChatInfo,
  updateTelClick,
} from '../service/ChatBotService';
import {
  addMessage,
  createQnaMessage,
  getChatDataRealTime,
  getContentsMessageTemplate,
  getLastCycleInfo,
  getMessages,
  getPetData,
  sendFcmToMe,
  updateChatLastRead,
  updateCommunicationRead,
  updateFamilyName,
  updateMessage,
} from '../service/ChattingService';
import {
  addMessages,
  isLoaded,
  isShownViewer,
  selectContentsMessageTemplates,
  selectMessages,
  selectNoContentMessage,
  selectSelectMessageData,
  setContentsMessageTemplates,
  setCurrentChat,
  setLoaded,
  setMessageData,
  setMessages,
  setNoContentsMessage,
  setNotificationMessages,
  setShowViewer,
  setWaitingMessage,
} from '../slices/chattingSlice';
import { AgreementModal } from './AgreementModal';
import ChattingHeader from './ChattingHeader';
import ChattingTextBox from './ChattingTextBox';
import { InputDataModal } from './InputDataModal';
import ChattingBoxModule from './messageBox/ChattingBoxModule';
import { MessageDTO } from './messageBox/MessageDTO';
let timer;
let addPetInputInterval;
const MessageTemplate = ({
  onAlarmClick,
  isSubscribePush,
  onHomeInstallClick,
  onOpenRegisterModal,
  onOpenBlockGuideModal,
}) => {
  const toastOption: ToastOptions = {
    position: 'top-center',
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    closeButton: false,
    draggable: true,
    transition: Slide,
  };
  const globalInfo = useAppSelector(selectGlobalInfo);
  const globalConfig = useAppSelector(selectGlobalConfig);
  const isQNAShowModal = useAppSelector(isQNAShow);
  const isShownViewerPopup = useAppSelector(isShownViewer);
  const selectMessageData = useAppSelector(selectSelectMessageData);
  const noContentMessage = useAppSelector(selectNoContentMessage);
  const contentsMessageTemplates = useAppSelector(
    selectContentsMessageTemplates,
  );
  const viewerContentsRef = useRef(null);
  const imageViewRef = useRef(null);
  const messages = useAppSelector(selectMessages);
  const isMessageLoaded = useAppSelector(isLoaded);
  const [openContentsInterval, setOpenContentsInterval] = useState(null);
  const { db } = useFirebase();
  const globalAuth = useAppSelector(selectGlobalAuth);
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [inputType, setInputType] = useState('text');
  const [stackedMessages, setStackedMessages] = useState([]);
  const [petData, setPetData] = useState(null);
  const [qnaTemplateKey, setQnaTemplateKey] = useState('');
  const [qnaTemplateType, setQnaTemplateType] = useState('');
  const [qnaKey, setQnaKey] = useState('');
  const [qnaCycleKey, setQnaCycleKey] = useState('');
  const [qnaEventKey, setQnaEventKey] = useState('');
  const [isShownQnaResultModal, setShownQnaResultModal] = useState(false);
  const [qnaMessageData, setQnaMessageData] = useState(null);
  const [lastReadTimeInfo, setLastReadTimeInfo] = useState(null);
  const [isShownAgreement, setShownAgreement] = useState(false);
  const [chatInfoList, setChatInfoList] = useState([]);
  const [isFirstLoad, setFirstLoad] = useState(false);
  const [isChatBotLoad, setChatBotLoad] = useState(false);
  const [triggerMessageTemplateList, setTriggerMessageTemplateList] =
    useState(null);
  const [inputMessageAction, setInputMessageAction] = useState(null);
  const [isShownInputModal, setShownInputModal] = useState(false);
  const onloadEvent = {
    getFamilyInfo: async (familyKey: string) => {
      const familyRef = db.collection('Family').doc(familyKey);
      return await familyRef.get().then((doc) => {
        return doc.data();
      });
    },
    getChatInfoList: async (familyKey: string) => {
      return db
        .collection('Hospital')
        .doc(globalInfo.hospitalKey)
        .collection('Chat')
        .where('familyInfo.key', '==', familyKey)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            return undefined;
          }
          return querySnapshot.docs.map((doc) => {
            return doc.data();
          });
        })
        .catch((error) => {
          captureException(error);
          return undefined;
        });
    },
    getHospitalInfo: async () => {
      const hospitalRef = db.collection('Hospital').doc(globalInfo.hospitalKey);
      return await hospitalRef.get().then(async (doc) => {
        const hospitalInfo = doc.data();
        const hospitalPhoneNumber = hospitalInfo?.phoneNumber;
        const hospitalReportPhoneNumber = hospitalInfo?.reportPhoneNumber;
        const hospitalVirtualNumber = hospitalInfo?.cti?.virtualNumber;
        const chatBotFullTimeActive = hospitalInfo?.chatBotFullTimeActive;
        const activeChatBotOnPublicHoliday =
          hospitalInfo?.activeChatBotOnPublicHoliday;
        const chatBotActiveHoursInfo = hospitalInfo?.chatBotActiveHoursInfo;
        dispatch(
          globalReducers.setInfoChatBotFullTimeActive(chatBotFullTimeActive),
        );
        dispatch(
          globalReducers.setInfoActiveChatBotOnPublicHoliday(
            activeChatBotOnPublicHoliday,
          ),
        );
        dispatch(
          globalReducers.setInfoChatBotActiveHoursInfo(chatBotActiveHoursInfo),
        );
        dispatch(
          globalReducers.setInfoHospitalReportPhoneNumber(
            hospitalReportPhoneNumber,
          ),
        );
        dispatch(
          globalReducers.setInfoHospitalVirtualNumber(hospitalVirtualNumber),
        );
        dispatch(globalReducers.setInfoHospitalTelNumber(hospitalPhoneNumber));
        const isChatBotActive = await isActiveChatBotTime(
          chatBotFullTimeActive,
          activeChatBotOnPublicHoliday,
          chatBotActiveHoursInfo,
        );
        dispatch(globalReducers.setChatBotActive(isChatBotActive));
      });
    },
    getChatData: () => {
      return getChatDataRealTime(
        globalInfo.hospitalKey,
        globalInfo.chatKey,
        (chatInfo) => {
          if (!chatInfo) {
            dispatch(globalReducers.setConfigIsLoaded(true));
            dispatch(globalReducers.setConfigIsMessageTextAreaReadOnly(true));
            dispatch(globalReducers.setConfigIsTimeOutMessage(true));
            return;
          }
          dispatch(
            globalReducers.setInfoTagList(
              chatInfo?.tagList ? chatInfo.tagList : [],
            ),
          );
          setLastReadTimeInfo(chatInfo.lastReadTimeInfo);
          dispatch(
            globalReducers.setChatBotActive(
              chatInfo.chatBotActive === undefined || chatInfo.chatBotActive,
            ),
          );

          if (chatInfo.isInstalledHomeScreen) {
            dispatch(
              globalReducers.setInfoIsInstalledHomeScreen(
                chatInfo.isInstalledHomeScreen,
              ),
            );
            dispatch(setLoaded(true));
          } else {
            dispatch(globalReducers.setInfoIsInstalledHomeScreen(false));
          }
          dispatch(
            globalReducers.setInfoHospitalName(chatInfo.hospitalInfo.name),
          );
          dispatch(globalReducers.setInfoFamilyInfo(chatInfo.familyInfo));
          // dispatch(globalReducers.setConfigIsLoaded(true));
          if (chatInfo.petInfo) {
            dispatch(globalReducers.setInfoPetInfo(chatInfo.petInfo));
          }
          dispatch(
            globalReducers.setConfigIsMessageTextAreaReadOnly(!chatInfo.active),
          );
          dispatch(globalReducers.setConfigIsTimeOutMessage(!chatInfo.active));
          if (
            chatInfo.recentMessage?.writerType !== 'nok' &&
            !chatInfo.recentMessage?.read &&
            !document.hidden
          ) {
            updateChatLastRead(globalInfo.hospitalKey, globalInfo.chatKey);
          }
          dispatch(globalReducers.setRecentMessage(chatInfo.recentMessage));
        },
      );
    },
    initializeQueryParameters: () => {
      const hospitalKey = router.query.hospital?.toString();
      const chatKey = router.query.chat?.toString();
      const chartKey = router.query.chart?.toString();
      const communicationKey = router.query.communicationKey?.toString();
      if (hospitalKey && chatKey) {
        dispatch(setCurrentChat(''));
        dispatch(globalReducers.setInfoHospitalKey(hospitalKey));
        dispatch(globalReducers.setInfoChatKey(chatKey));
        if (communicationKey) {
          updateCommunicationRead(hospitalKey, communicationKey);
        }
      } else if (hospitalKey && chartKey) {
        dispatch(setCurrentChat(''));
        dispatch(globalReducers.setInfoHospitalKey(hospitalKey));
        dispatch(globalReducers.setInfoChatKey(chartKey));
      } else {
        router.push('/404');
      }
    },
  };
  const isFirstUserMessage = (messages) => {
    for (const message of messages) {
      if (message.writerType === 'nok') {
        return false;
      }
    }
    return true;
  };

  const isFullPetData = (petData) => {
    if (!petData) {
      return false;
    }
    const { petName, petType, petBirthday, petGender, petSpecies } = petData;
    if (
      !petName.replaceAll(' ', '') ||
      petType === undefined ||
      !petGender
      // || !petBirthday ||
      // !petSpecies
    ) {
      return false;
    }
    return true;
  };

  const sendA2HSMessage = async (isContainInfo) => {
    if (isContainInfo) {
      const messageDto1 = new MessageDTO({
        writer: globalInfo.hospitalName,
        writerType: 'bot_first_message',
        text: '정보가 제출되었습니다.',
        createdDate: dayjs().add(1000, 'milliseconds').valueOf(),
      });
      setTimeout(() => {
        addMessage(
          messages,
          null,
          globalInfo,
          [messageDto1],
          true,
          messageDto1.writerType,
        );
      }, 1000);
    }

    const messageDto2 = new MessageDTO({
      writer: globalInfo.hospitalName,
      writerType: 'bot_first_message',
      text:
        `${globalInfo.hospitalName}의 메신저 즐겨찾기를 하면 다음과 같은 기능을 사용할 수 있어요.\n` +
        '• 실시간 푸시 알림을 받을 수 있습니다.\n' +
        '• 사진 및 동영상 파일등을 전송할 수 있습니다.\n' +
        '• 동물병원에서 제공한 반려동물의 기록을 보관하고 다운로드 받을 수 있습니다.\n',
      actions: [
        {
          link: 'internal://bot_first_message',
          type: 'button',
          name: '즐겨찾기 추가하기',
        },
      ],
      createdDate: dayjs().add(2000, 'milliseconds').valueOf(),
    });
    setTimeout(() => {
      addMessage(
        messages,
        null,
        globalInfo,
        [messageDto2],
        true,
        messageDto2.writerType,
      );
    }, 2000);
  };

  const sendPetDataMessage = async () => {
    // 펫 데이터 모달 오픈 메시지 전송
    const messageDto = new MessageDTO({
      writer: globalInfo.hospitalName,
      writerType: 'bot_first_message',
      text: '원장님의 답변을 기다리는 동안 아이의 정보를 미리 작성하시겠어요? 원장님이 아이의 정보를 토대로 더 구체적인 피드백을 드릴 수 있어요. 작성 시간은 30초 미만이에요.',
      actions: [
        {
          link: 'internal://bot_first_message',
          type: 'button',
          name: '정보 작성',
          data: {
            read: false,
          },
        },
      ],
      createdDate: dayjs().add(1500, 'milliseconds').valueOf(),
    });

    setTimeout(() => {
      addMessage(
        messages,
        null,
        globalInfo,
        [messageDto],
        true,
        messageDto.writerType,
      );
    }, 1500);
  };

  const inputFamilyAndPetData = async (inputFamilyName, inputPetData) => {
    const messageData = _.cloneDeep(inputMessageAction);
    messageData.actions[0].data.read = true;
    updateMessage(globalInfo, messageData);
    updateFamilyName(
      inputFamilyName,
      globalInfo.hospitalKey,
      globalInfo.chatKey,
      globalInfo.familyInfo.key,
    )
      .then(() => {
        if (globalInfo.petInfo && globalInfo.petInfo.key) {
          inputPetData.petKey = globalInfo.petInfo.key;
          updatePetData(
            inputPetData,
            globalInfo.hospitalKey,
            globalInfo.hospitalName,
            globalInfo.familyInfo,
            globalInfo.chatKey,
            globalInfo.tagList,
          ).then(() => {
            setInputMessageAction(null);
            dispatch(globalReducers.setConfigIsLoaded(true));
            setPetData(inputPetData);
          });
        } else {
          createPetData(
            inputPetData,
            globalInfo.hospitalKey,
            globalInfo.chatKey,
            globalInfo.familyInfo.key,
          ).then(() => {
            setInputMessageAction(null);
            dispatch(globalReducers.setConfigIsLoaded(true));
            setPetData(inputPetData);
          });
        }
      })
      .catch((e) => {
        console.log('updateFamilyNameError', e);
      });
  };

  // 보호자의 메시지 전송
  const addChatMessage = (message) => {
    const familyName = globalInfo.familyInfo.name;
    const newMessageData = new MessageDTO({
      text: message,
      writer: familyName,
      writerType: 'nok',
    });
    addMessage(messages, null, globalInfo, [newMessageData])
      .then(async (messageKey) => {
        setChatBotLoad(false);
        if (isFirstUserMessage(messages)) {
          if (!isFullPetData(petData)) {
            sendPetDataMessage();
          } else if (!checkIsAppInstalled()) {
            sendA2HSMessage(false);
          }
        }
        const trimMessage = message.replaceAll(' ', '');
        if (trimMessage.includes('예약신청')) {
          const triggerMessageTemplate = getMessageTemplateByKeyword(
            'reply_request_reservation',
          );
          if (triggerMessageTemplate) {
            addTriggerMessage(
              triggerMessageTemplate,
              messages,
              {
                text: message,
                key: messageKey,
              },
              globalInfo,
              'request_reservation',
            );
          }
        } else if (trimMessage.includes('시간안내')) {
          const triggerMessageTemplate = getMessageTemplateByKeyword(
            'reply_request_opening_hours',
          );
          if (triggerMessageTemplate) {
            addTriggerMessage(
              triggerMessageTemplate,
              messages,
              {
                text: message,
                key: messageKey,
              },
              globalInfo,
              'request_opening_hours',
            );
          }
        } else if (trimMessage.includes('위치및주차안내')) {
          const triggerMessageTemplate = getMessageTemplateByKeyword(
            'reply_request_parking_and_location',
          );
          if (triggerMessageTemplate) {
            addTriggerMessage(
              triggerMessageTemplate,
              messages,
              {
                text: message,
                key: messageKey,
              },
              globalInfo,
              'request_parking_and_location',
            );
          }
        } else if (trimMessage.includes('비용안내')) {
          const triggerMessageTemplate =
            getMessageTemplateByKeyword('reply_request_cost');
          if (triggerMessageTemplate) {
            addTriggerMessage(
              triggerMessageTemplate,
              messages,
              {
                text: message,
                key: messageKey,
              },
              globalInfo,
              'request_cost',
            );
          }
        } else if (trimMessage.includes('공지사항')) {
          const triggerMessageTemplate = getMessageTemplateByKeyword(
            'reply_request_notice',
          );
          if (triggerMessageTemplate) {
            addTriggerMessage(
              triggerMessageTemplate,
              messages,
              {
                text: message,
                key: messageKey,
              },
              globalInfo,
              'request_cost',
            );
          }
          if (globalInfo.chatBotActive) {
            // const trimMessage = message.replaceAll(' ', '');
            // if (trimMessage.includes('예약신청')) {
            //   const triggerMessageTemplate = getMessageTemplateByKeyword(
            //     'reply_request_reservation',
            //   );
            //   if (triggerMessageTemplate) {
            //     addTriggerMessage(
            //       triggerMessageTemplate,
            //       messages,
            //       {
            //         text: message,
            //         key: messageKey,
            //       },
            //       globalInfo,
            //       'request_reservation',
            //     );
            //   }
            // } else if (trimMessage.includes('시간안내')) {
            //   const triggerMessageTemplate = getMessageTemplateByKeyword(
            //     'reply_request_opening_hours',
            //   );
            //   if (triggerMessageTemplate) {
            //     addTriggerMessage(
            //       triggerMessageTemplate,
            //       messages,
            //       {
            //         text: message,
            //         key: messageKey,
            //       },
            //       globalInfo,
            //       'request_opening_hours',
            //     );
            //   }
            // } else if (trimMessage.includes('위치및주차안내')) {
            //   const triggerMessageTemplate = getMessageTemplateByKeyword(
            //     'reply_request_parking_and_location',
            //   );
            //   if (triggerMessageTemplate) {
            //     addTriggerMessage(
            //       triggerMessageTemplate,
            //       messages,
            //       {
            //         text: message,
            //         key: messageKey,
            //       },
            //       globalInfo,
            //       'request_parking_and_location',
            //     );
            //   }
            // } else if (trimMessage.includes('비용안내')) {
            //   const triggerMessageTemplate =
            //     getMessageTemplateByKeyword('reply_request_cost');
            //   if (triggerMessageTemplate) {
            //     addTriggerMessage(
            //       triggerMessageTemplate,
            //       messages,
            //       {
            //         text: message,
            //         key: messageKey,
            //       },
            //       globalInfo,
            //       'request_cost',
            //     );
            //   }
            // } else if (trimMessage.includes('공지사항')) {
            //   const triggerMessageTemplate = getMessageTemplateByKeyword(
            //     'reply_request_notice',
            //   );
            //   if (triggerMessageTemplate) {
            //     addTriggerMessage(
            //       triggerMessageTemplate,
            //       messages,
            //       {
            //         text: message,
            //         key: messageKey,
            //       },
            //       globalInfo,
            //       'request_cost',
            //     );
            //   }
          } else {
            if (timer) {
              clearTimeout(timer);
            }
            const latestVetfluxMessage = messages.reduce(
              (latestMessage, message) => {
                if (
                  message.writerType === 'vetflux' &&
                  message.createdDate > (latestMessage?.createdDate || 0)
                ) {
                  return message;
                }
                return latestMessage;
              },
              { createdDate: 0 },
            );
            console.log('latestVetfluxMessage', latestVetfluxMessage);
            const timeDifference = dayjs()
              .subtract(3, 'minutes')
              .isBefore(latestVetfluxMessage.createdDate);

            if (timeDifference) {
              console.log('마지막 메시지가 3분 이내에 도착했습니다.');
              return;
            } else {
              setChatBotLoad(true);
              console.log('마지막 메시지가 3분 이상 전에 도착했습니다.');
            }
            newMessageData.key = messageKey;
            stackedMessages.push(newMessageData);
            setStackedMessages(stackedMessages);
            timer = setTimeout(async () => {
              const recommendContents = await getRecommendContents(
                globalInfo.hospitalKey,
                stackedMessages
                  .map((message) => {
                    return message.text;
                  })
                  .join(' '),
                contentsMessageTemplates,
              );
              setChatBotLoad(false);

              if (recommendContents.length === 0) {
                setStackedMessages([]);
              }
              if (recommendContents.length > 0) {
                if (recommendContents[0].category !== 'info') {
                  await addRecommendGuideMessage(
                    stackedMessages,
                    recommendContents,
                    globalInfo,
                  )
                    .then(() => {
                      sendFcmToMe(
                        globalInfo.chatKey,
                        {
                          hospitalName: globalInfo.hospitalName,
                          hospitalKey: globalInfo.hospitalKey,
                        },
                        globalInfo.familyInfo.key,
                      );
                      setStackedMessages([]);
                    })
                    .catch((error) => {
                      captureException(error);
                      setStackedMessages([]);
                    });
                } else {
                  await addRecommendContentsMessage(
                    stackedMessages
                      .map((message) => {
                        return message.text;
                      })
                      .join(' '),
                    globalInfo,
                    recommendContents,
                    '',
                    false,
                  )
                    .then(() => {
                      setStackedMessages([]);
                    })
                    .catch((error) => {
                      captureException(error);
                      setStackedMessages([]);
                    });
                }
              }
            }, 3000);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        captureException(error);
      });
  };

  //메시지의 버튼 클릭 Events
  const initModuleEvent = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    window.message = {
      onClick: (messageData: string) => {
        const jsonParseData = JSON.parse(messageData);
        if (jsonParseData.images?.length > 0) {
          dispatch(setShowViewer(true));
          dispatch(
            setMessageData({
              link: jsonParseData.images[0].link,
              mediaType: 0,
            }),
          );
        }
        if (jsonParseData.videos?.length > 0) {
          dispatch(setShowViewer(true));
          dispatch(
            setMessageData({
              link: jsonParseData.videos[0].link,
              mediaType: 1,
            }),
          );
        }
        if (jsonParseData.files?.length > 0) {
          downloadURL(jsonParseData.files[0].link, jsonParseData.files[0].name);
        }
      },
      onNoAnswerClick: (data) => {
        const messageData = JSON.parse(data);
        addNoAnswerBotMessage(globalInfo, messages, messageData);
      },
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    window.qna = {
      onClick: (index, data) => {
        const messageData = JSON.parse(data);
        const messageAction = messageData.actions[index];
        setShownQnaResultModal(true);
        setQnaKey(messageAction.data?.qnaKey);
      },
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    window.action = {
      onClick: async (index, data) => {
        const messageData = JSON.parse(data);
        const messageAction = messageData.actions[index];
        const link = messageAction.link?.toString();
        if (link.startsWith('internal://', 0)) {
          if (
            link.includes('bot_action') ||
            link.includes('bot_recommend_action')
          ) {
            //
            addNextActionIsHelpfulBotMessage(
              messageAction,
              messageData,
              globalInfo,
              messages,
            );
          } else if (link.includes('bot_first_message')) {
            if (messageAction.name === '정보 작성') {
              setShownInputModal(true);
              messageAction.data.read = true;

              if (messageAction.data === undefined) {
                messageAction.data = {};
              }
              setInputMessageAction(messageData);
            } else {
              onHomeInstallClick(true);
            }
          } else if (link.includes('bot_first_guide')) {
            const actionName = messageAction.name;
            addChatMessage(actionName);
          } else if (link.includes('bot_guide_action')) {
            const data = messageAction.data;
            data.read = true;
            updateMessage(globalInfo, messageData);
            if (messageAction.name === '네') {
              const userMessageDto = new MessageDTO({
                writer: globalInfo.familyInfo.name,
                writerType: 'nok',
                text: '네',
              });
              addMessage(messages, null, globalInfo, [userMessageDto]).then(
                () => {
                  setChatBotLoad(true);
                  setTimeout(() => {
                    setTimeout(() => {
                      setChatBotLoad(false);
                    }, 1000);
                    addRecommendContentsMessage(
                      data.message,
                      globalInfo,
                      data.recommendContents,
                      noContentMessage,
                      false,
                      data.chatBotKey,
                    );
                  }, 2000);
                },
              );
            } else {
              const userMessageDto = new MessageDTO({
                writer: globalInfo.familyInfo.name,
                writerType: 'nok',
                text: '아니요',
              });
              const dto = new MessageDTO({
                writer: globalInfo.hospitalName,
                writerType: 'bot_guide_action',
                text: '수의사님이 답변주실때 까지 잠시 기다려주시면 감사하겠습니다.',
                createdDate: dayjs().add(2, 'second').valueOf(),
              });
              addMessage(messages, null, globalInfo, [userMessageDto, dto]);
            }
          } else if (link.includes('bot_certificate_vaccine')) {
            dispatch(globalReducers.setConfigSelectedRegisterModalIndex(3));
            dispatch(globalReducers.setConfigIsShownInformationModal(true));
          } else if (link.includes('bot_notification')) {
            if (getMobileOS() === 'iOS') {
              if (isIOSInstalled()) {
                dispatch(globalReducers.setConfigSelectedRegisterModalIndex(1));
                dispatch(globalReducers.setConfigIsShownInformationModal(true));
              } else {
                dispatch(globalReducers.setConfigSelectedRegisterModalIndex(4));
                dispatch(globalReducers.setConfigIsShownInformationModal(true));
              }
            } else {
              const isStandalone = window.matchMedia(
                '(display-mode: standalone)',
              ).matches;
              if (isStandalone) {
                dispatch(globalReducers.setConfigSelectedRegisterModalIndex(1));
                dispatch(globalReducers.setConfigIsShownInformationModal(true));
              } else {
                dispatch(globalReducers.setConfigSelectedRegisterModalIndex(4));
                dispatch(globalReducers.setConfigIsShownInformationModal(true));
              }
            }
          } else if (link.includes('tel')) {
            window.open(`tel:${globalInfo.hospitalTelNumber}`, '_system');
            updateTelClick(globalInfo, messageAction, messageData);
          } else {
            if (globalConfig.isMessageTextAreaReadOnly) {
              alert('대화가 종료되어 병원 방문 후 메시지 입력이 가능합니다.');
            } else {
              if (messageAction.data) {
                if (messageAction.data.qnaTemplateKey) {
                  setQnaTemplateKey(messageAction.data.qnaTemplateKey);
                }
                if (messageAction.data.cycleKey) {
                  setQnaCycleKey(messageAction.data.cycleKey);
                }
                if (messageAction.data.eventKey) {
                  setQnaEventKey(messageAction.data.eventKey);
                }
              }
              if (messageAction?.data?.requestType) {
                setQnaTemplateType(messageAction?.data?.requestType);
              } else {
                setQnaTemplateType('qna');
              }
              setQnaMessageData(messageData);
              dispatch(setQNAShow(true));
            }
          }
        } else if (link.startsWith('tel://', 0)) {
          if (globalInfo.hospitalVirtualNumber) {
            window.location.href = `tel:${globalInfo.hospitalVirtualNumber}`;
          } else if (globalInfo.hospitalTelNumber) {
            window.location.href = `tel:${globalInfo.hospitalTelNumber}`;
          } else {
            alert('등록된 병원 전화번호가 없습니다.');
          }
          updateTelClick(globalInfo, messageAction, messageData);
        } else {
          const data = messageAction.data;
          if (data) {
            const description = data.description;
            if (description) {
              addDescriptionBotMessage(
                globalInfo,
                messageData,
                messageAction,
                messages,
              );
              return;
            }
          }
          handleActionContentsClick(messageData, index, link);
        }
      },
    };
  };

  // 콘텐츠 클릭 시, 링크 열거나 링크 없으면 답변 메시지 생성
  const handleActionContentsClick = (messageData, index, link) => {
    if (link) {
      if (isInAppBrowser()) {
        openContentOnInAppBrowser(messageData, index, link);
      } else {
        openContents(messageData, index, link);
      }
    } else {
      messageData.actions[index].data.read = true;
      messageData.actions[index].data.duration = 0;
      updateMessage(globalInfo, messageData);
    }
  };

  // 콘텐츠 열기
  const openContents = (messageData, index, link) => {
    window.open(link, '_blank');
    messageData.actions[index].data.read = true;
    const openTime = dayjs().valueOf();
    const contentReturnListener = () => {
      if (document.visibilityState === 'visible') {
        window.removeEventListener('visibilitychange', contentReturnListener);
        messageData.actions[index].data.duration = dayjs().valueOf() - openTime;
        addNextActionBotMessage(
          globalInfo,
          messages,
          messageData,
          messageData.actions[index],
          contentsMessageTemplates,
        );
      }
    };
    window.addEventListener('visibilitychange', contentReturnListener, false);
  };

  // 콘텐츠 열기
  const openContentOnInAppBrowser = (messageData, index, link) => {
    const page = window.open(link, '_blank');
    messageData.actions[index].data.read = true;
    const openTime = dayjs().valueOf();
    let time = 0;
    if (!openContentsInterval) {
      const interval = setInterval(() => {
        time += 1;
        if (page.closed) {
          messageData.actions[index].data.duration =
            dayjs().valueOf() - openTime;
          addNextActionBotMessage(
            globalInfo,
            messages,
            messageData,
            messageData.actions[index],
            contentsMessageTemplates,
          );
          clearInterval(interval);
          setOpenContentsInterval(null);
        } else {
          if (time > 600) {
            clearInterval(interval);
            setOpenContentsInterval(null);
          }
        }
      }, 1000);
      setOpenContentsInterval(interval);
    } else {
      clearInterval(openContentsInterval);
      openContentOnInAppBrowser(messageData, index, link);
    }
  };

  const createGuideLocalMessageDto = (
    message,
    time = dayjs().valueOf(),
    actions?: any,
  ) => {
    const messageDto = new MessageDTO({
      key: randomString(),
      writer: globalInfo.hospitalName,
      writerType: 'bot_first_guide',
      text: message,
      createdDate: time,
    });
    if (actions) {
      messageDto.actions = actions;
    }
    return messageDto.toPlainObj();
  };

  // 문진 입력 완료, 업로드
  const onQnaUploadClick = (qnaData) => {
    uploadQna(qnaData)
      .then((response) => {
        setQnaTemplateType('');
        const updatedQnaMessageData = { ...qnaMessageData };
        updatedQnaMessageData.actions[0].data.read = true;
        updateMessage(globalInfo, updatedQnaMessageData);
        const messageIndex = messages?.findIndex(
          (message) => message.key === qnaMessageData.key,
        );
        if (messageIndex !== -1) {
          const updatedMessages = [...messages];
          updatedMessages[messageIndex] = updatedQnaMessageData;
          dispatch(setMessages(updatedMessages));
        }
        dispatch(setQNAInfoList(initialState.qnaInfoList));
        dispatch(setQNAShow(false));
        dispatch(globalReducers.setConfigIsLoaded(true));
        const familyName = globalInfo.familyInfo.name;
        const newMessageData = new MessageDTO({
          writer: familyName,
          writerType: 'nok',
          text:
            qnaData.type === 'request_reservation' &&
            qnaData.petInfo &&
            qnaData.petInfo.name
              ? `${addSuffix(
                  qnaData.petInfo.name,
                  '이',
                )}의 예약이 신청되었습니다.`
              : `답변을 제출했습니다.`,
          actions: [
            {
              name:
                qnaData.type === 'request_reservation' &&
                qnaData.petInfo &&
                qnaData.petInfo.name
                  ? '예약 신청 확인'
                  : '답변 확인',
              type: qnaData.type,
              link: 'internal://qna',
              data: {
                qnaKey: response.data.qnaData.key,
              },
            },
          ],
        });
        addMessage(
          messages,
          null,
          globalInfo,
          [newMessageData],
          qnaData.type === 'request_reservation' || qnaData.type === 'qna',
        ).then(async (messageKey) => {
          if (isFirstUserMessage(messages)) {
            if (!isFullPetData(petData)) {
              sendPetDataMessage();
            } else if (!checkIsAppInstalled()) {
              sendA2HSMessage(false);
            }
          }
          addNotification({
            key: '',
            familyInfo: globalInfo.familyInfo,
            petInfo: globalInfo.petInfo,
            hospitalInfo: {
              key: globalInfo.hospitalKey,
              name: globalInfo.hospitalName,
            },
            createdDate: dayjs().valueOf(),
            chatKey: globalInfo.chatKey,
            text: createQnaMessage(qnaData.qnaInfoList),
            messageType: 'talk',
            to: globalInfo.hospitalReportPhoneNumber
              ? globalInfo.hospitalReportPhoneNumber
              : '',
            qnaInfoList: qnaData.qnaInfoList,
            qnaTemplateKey: qnaData.qnaTemplateKey,
            qnaTemplateType: qnaData.type,
            qnaKey: response.data.qnaData.key,
            messageKey: messageKey,
            purposeType: globalInfo.lastPurposeType,
          });
        });
      })
      .catch((error) => {
        captureException(error);
        console.log(error);
      });
  };

  // 대화 변경(대화창 변경을 위한 다른 챗 클릭)
  const onChatInfoClick = (chatInfo) => {
    if (globalInfo.chatKey === chatInfo.key) {
      alert('현재 대화 중인 반려동물의 메신저 입니다.');
      return;
    }
    alert('선택하신 반려동물의 메신저로 이동합니다.');
    dispatch(setLoaded(false));
    setPetData(null);
    dispatch(setMessages([]));
    router.replace(`/?hospital=${globalInfo.hospitalKey}&chat=${chatInfo.key}`);
  };

  const onClickAgreement = (isAgreement) => {
    const familyKey = globalInfo.familyInfo.key;
    if (isAgreement) {
      db.collection('Family')
        .doc(familyKey)
        .set(
          {
            agreementInfoList: firebase.firestore.FieldValue.arrayUnion({
              phoneNumber: globalInfo.familyInfo.phoneNumber,
              isAgreement: isAgreement,
              date: dayjs().valueOf(),
            }),
          },
          { merge: true },
        )
        .then(() => {
          setShownAgreement(false);
          toast(`개인정보 수집에 동의가 완료되었습니다.`, toastOption);
        })
        .catch((error) => {
          captureException(error);
          toast(`일시적인 오류가 발생하였습니다.`, toastOption);
        });
    } else {
      setShownAgreement(false);
      toast(`개인정보 수집에 동의 거절이 완료되었습니다.`, toastOption);
      setTimeout(() => {
        setShownAgreement(true);
      }, 500);
    }
  };

  const handleClickOutSide = (event) => {
    if (
      viewerContentsRef.current &&
      (viewerContentsRef.current === (event.target as Node) ||
        (imageViewRef.current &&
          imageViewRef.current.contains(event.target as Node)))
    ) {
      dispatch(setShowViewer(false));
    }
  };

  initModuleEvent();

  useEffect(() => {
    if (!globalInfo.lastCycleInfo) {
      return;
    }
    dispatch(globalReducers.setInfoCycleKey(globalInfo.lastCycleInfo.key));
    const lastEvent = getLastEvent(globalInfo.lastCycleInfo);
    dispatch(globalReducers.setInfoLastEventInfo(lastEvent));
    if (lastEvent) {
      const qnaType = getQNAType(lastEvent);
      dispatch(globalReducers.setInfoQnaType(qnaType));
    }
  }, [globalInfo.lastCycleInfo]);

  useEffect(() => {
    onloadEvent.initializeQueryParameters();
  }, [router.query]);

  useEffect(() => {
    let contentMessageTemplateSnapshot;
    let chatDataSnapshot;
    if (
      globalInfo.hospitalKey &&
      globalInfo.chatKey &&
      globalAuth.currentUser
    ) {
      onloadEvent.getHospitalInfo().then(() => {
        chatDataSnapshot = onloadEvent.getChatData();
      });
      contentMessageTemplateSnapshot = getContentsMessageTemplate(
        globalInfo,
        (result) => {
          dispatch(setContentsMessageTemplates(result));
        },
      );
    }
    return () => {
      if (contentMessageTemplateSnapshot) {
        if (typeof contentMessageTemplateSnapshot === 'function') {
          contentMessageTemplateSnapshot();
        }
      }
      if (chatDataSnapshot) {
        chatDataSnapshot();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    globalInfo.hospitalKey,
    globalInfo.chatKey,
    globalAuth.currentUser,
    globalConfig.isLoaded,
  ]);

  useEffect(() => {
    let lastCycleInfoSnapshot;
    if (globalInfo.hospitalKey && globalInfo.hospitalName) {
      lastCycleInfoSnapshot = getLastCycleInfo(globalInfo, (cycleInfo) => {
        dispatch(globalReducers.setInfoLastCycleInfo(cycleInfo));
      });
      if (globalInfo.petInfo && globalInfo.petInfo.key) {
        if (addPetInputInterval) {
          clearTimeout(addPetInputInterval);
        }
        getPetData(globalInfo.petInfo.key).then((doc) => {
          if (isFullFilledPetData(doc.data())) {
            updatePetDataOnChatInfo(
              doc.data(),
              globalInfo.hospitalKey,
              globalInfo.hospitalName,
              globalInfo.familyInfo,
              globalInfo.chatKey,
              globalInfo.tagList,
            );
            setInputType('text');
            setPetData(doc.data());
          } else {
            setInputType('text');
            setPetData(doc.data());
            // dispatch(setMessages([]));
            // startGetPetDataChatBot(doc.data());
          }
        });
      } else {
        setInputType('text');
        // startGetPetDataChatBot(null);
      }
    }
    return () => {
      if (lastCycleInfoSnapshot) {
        lastCycleInfoSnapshot();
      }
    };
  }, [globalInfo.hospitalKey, globalInfo.petInfo, globalInfo.hospitalName]);

  useEffect(() => {
    if (globalInfo.familyInfo && globalInfo.familyInfo.key) {
      onloadEvent
        .getFamilyInfo(globalInfo.familyInfo.key)
        .then((familyInfo) => {
          if (familyInfo) {
            const agreementInfoList = familyInfo.agreementInfoList;
            if (!agreementInfoList) {
              setShownAgreement(true);
            } else {
              const phoneNumber = globalInfo.familyInfo.phoneNumber;
              const filteredList = agreementInfoList.filter((agreementInfo) => {
                return agreementInfo.phoneNumber === phoneNumber;
              });
              setShownAgreement(filteredList.length === 0);
            }
            const subscribeInfoList = familyInfo.subscribeInfoList;
            if (subscribeInfoList && globalConfig.uuid) {
              const isIncludes = subscribeInfoList.includes(globalConfig.uuid);
              dispatch(globalReducers.setInfoIsSubscribePush(isIncludes));
            } else {
              dispatch(globalReducers.setInfoIsSubscribePush(false));
            }
          }
        });
      onloadEvent
        .getChatInfoList(globalInfo.familyInfo.key)
        .then((chatInfoList) => {
          setChatInfoList(chatInfoList);
        });
    }
  }, [globalInfo.familyInfo, globalConfig.uuid]);

  useEffect(() => {
    window.addEventListener('click', handleClickOutSide, true);
    return () => {
      window.removeEventListener('click', handleClickOutSide);
    };
  }, []);

  useEffect(() => {
    const preventGoBack = () => {
      dispatch(setQNAShow(false));
    };
    if (isQNAShowModal) {
      history.pushState(null, '', location.href);
      window.addEventListener('popstate', preventGoBack);
    }

    return () => window.removeEventListener('popstate', preventGoBack);
  }, [isQNAShowModal]);

  useEffect(() => {
    let unSubscribeMessageSnapshot;
    if (globalInfo.chatKey && globalInfo.hospitalKey) {
      unSubscribeMessageSnapshot = getMessages(
        globalInfo.chatKey,
        globalInfo.hospitalKey,
        (messages, notificationMessages) => {
          dispatch(setLoaded(true));
          const visibleMessage = messages.filter((message) => {
            return message.createdDate <= now();
          });
          if (
            visibleMessage[visibleMessage.length - 1]?.writerType ===
              'vetflux' &&
            timer
          ) {
            clearTimeout(timer);
          }
          dispatch(addMessages(_.cloneDeep(messages)));
          // dispatch(setMessages(_.cloneDeep(messages)));
          dispatch(setNotificationMessages(notificationMessages));
        },
      );
    }
    return () => {
      if (unSubscribeMessageSnapshot) {
        unSubscribeMessageSnapshot();
      }
    };
  }, [globalInfo.chatKey, globalInfo.hospitalKey]);

  const visibleChangeListener = () => {
    if (!globalInfo.recentMessage) {
      return;
    }
    if (
      globalInfo.recentMessage.writerType !== 'nok' &&
      !globalInfo.recentMessage.read &&
      !document.hidden
    ) {
      updateChatLastRead(globalInfo.hospitalKey, globalInfo.chatKey);
    }
  };

  useEffect(() => {
    const today = dayjs().format('YYYY-MM-DD');
    if (messages) {
      const getLastMessages = messages[messages.length - 1];
      const filteredMessages = messages.filter((message) => {
        const messageDate = dayjs(message.createdDate).format('YYYY-MM-DD');
        return messageDate === today && message.writerType === 'vetflux';
      });
      if (
        getLastMessages?.writerType !== 'bot_first_guide' &&
        triggerMessageTemplateList &&
        !isFirstLoad &&
        filteredMessages.length === 0
      ) {
        const actions = getFirstGuideAction();
        const messageDto = createGuideLocalMessageDto(
          `안녕하세요 보호자님! 챗봇 베티에요. 문의사항을 남겨주시면 원장님께 전달 드리겠습니다.`,
          dayjs().valueOf(),
          actions.length > 0 && actions,
        );
        dispatch(addMessages([messageDto]));
        setFirstLoad(true);
      }
    }
  }, [messages, triggerMessageTemplateList, isFirstLoad]);

  useEffect(() => {
    window.removeEventListener('visibilitychange', visibleChangeListener);
    window.addEventListener('visibilitychange', visibleChangeListener, false);
    return () => {
      window.removeEventListener('visibilitychange', visibleChangeListener);
    };
  }, [globalInfo.recentMessage]);

  useEffect(() => {
    if (globalInfo.hospitalKey) {
      getReplyTriggerMessageTemplate(globalInfo.hospitalKey).then(
        (triggerMessageTemplateList) => {
          if (triggerMessageTemplateList) {
            triggerMessageTemplateList.forEach((triggerMessageTemplate) => {
              if (triggerMessageTemplate.keyword === 'reply_no_contents') {
                dispatch(setNoContentsMessage(triggerMessageTemplate));
              } else if (
                triggerMessageTemplate.keyword === 'reply_chat_message'
              ) {
                dispatch(setWaitingMessage(triggerMessageTemplate));
              }
            });
          }
          setTriggerMessageTemplateList(triggerMessageTemplateList);
        },
      );
    }
  }, [globalInfo]);

  const getMessageTemplateByKeyword = (keyword) => {
    if (!triggerMessageTemplateList) {
      return undefined;
    }
    const filteredTriggerMessageTemplate = triggerMessageTemplateList.filter(
      (triggerMessageTemplate) => {
        return triggerMessageTemplate.keyword === keyword;
      },
    );
    if (
      filteredTriggerMessageTemplate &&
      filteredTriggerMessageTemplate.length > 0
    ) {
      return filteredTriggerMessageTemplate[0];
    }
    return undefined;
  };

  const getFirstGuideAction = () => {
    if (!triggerMessageTemplateList) {
      return undefined;
    }
    return triggerMessageTemplateList
      .map((triggerMessageTemplate) => {
        const action = {
          name: '',
          link: 'internal://bot_first_guide',
          type: 'button',
        };
        if (triggerMessageTemplate.keyword === 'reply_request_reservation') {
          action.name = '예약 신청';
        }
        if (triggerMessageTemplate.keyword === 'reply_request_opening_hours') {
          action.name = '시간 안내';
        }
        if (triggerMessageTemplate.keyword === 'reply_request_cost') {
          action.name = '비용 안내';
        }
        if (triggerMessageTemplate.keyword === 'reply_request_notice') {
          action.name = '공지 사항';
        }
        if (
          triggerMessageTemplate.keyword ===
          'reply_request_parking_and_location'
        ) {
          action.name = '위치 및 주차 안내';
        }
        return action;
      })
      .filter((action) => {
        return action.name;
      });
  };

  return (
    <div className='flex flex-col fixed bottom-0 bg-white w-full h-full'>
      {isShownViewerPopup && selectMessageData.link && (
        <div
          ref={viewerContentsRef}
          className='flex justify-center items-center fixed z-[200] w-full h-full bg-[#00000045]'
        >
          {selectMessageData.mediaType === 0 ? (
            <div
              ref={imageViewRef}
              className='flex w-full justify-center items-center'
            >
              <img
                className='w-full bg-white'
                src={selectMessageData.link}
                alt='image_viewer'
              />
            </div>
          ) : (
            <div>
              <ReactPlayer
                className='absolute left-[50%] top-[50%] bg-black translate-x-[-50%] translate-y-[-50%]'
                url={selectMessageData.link}
                playing={true}
                controls={true}
                muted={true}
                width={'100%'}
              />
            </div>
          )}
        </div>
      )}

      <ChattingHeader
        hospitalName={globalInfo.hospitalName}
        hospitalTelNumber={globalInfo.hospitalTelNumber}
        hospitalVirtualNumber={globalInfo.hospitalVirtualNumber}
        petInfo={petData}
        onOpenRegisterModal={onOpenRegisterModal}
        chatInfoList={chatInfoList}
        onChatInfoClick={onChatInfoClick}
        onSubscribePushClick={onAlarmClick}
        isSubscribePush={isSubscribePush}
      />
      <ChattingBoxModule
        mode={1}
        messageList={messages}
        isLoaded={isMessageLoaded}
        lastReadTimeInfo={lastReadTimeInfo}
        isChatBotLoad={isChatBotLoad}
      />
      {inputType === 'text' ? (
        <ChattingTextBox
          addChatMessage={addChatMessage}
          onOpenBlockGuideModal={onOpenBlockGuideModal}
        />
      ) : (
        <div />
      )}

      <AnimatePresence>
        {isQNAShowModal && (
          <QNAModal
            qnaTemplateKey={qnaTemplateKey}
            qnaTemplateType={qnaTemplateType}
            cycleKey={qnaCycleKey}
            eventKey={qnaEventKey}
            hospitalName={globalInfo.hospitalName}
            onUploadClick={onQnaUploadClick}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isShownQnaResultModal && (
          <QnaResultModal
            qnaKey={qnaKey}
            onCloseClick={() => {
              setShownQnaResultModal(false);
            }}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isShownAgreement && (
          <AgreementModal onClickAgreement={onClickAgreement} />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {globalConfig.isShownInformationModal && (
          <InformationModal
            startIndex={globalConfig.selectedRegisterModalIndex}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isShownInputModal && (
          <InputDataModal
            onNextClick={async (familyName, petData) => {
              dispatch(globalReducers.setConfigIsLoaded(false));
              await inputFamilyAndPetData(familyName, petData);
              setShownInputModal(false);
              sendA2HSMessage(true);
              const index = messages.findIndex(
                (val) => val.key === inputMessageAction?.key,
              );
              const newMessages = _.cloneDeep(messages);
              if (newMessages[index]?.actions[0]?.data?.read !== undefined) {
                newMessages[index].actions[0].data.read = true;
              }
              dispatch(setMessages(newMessages));
            }}
            onCancelClick={() => setShownInputModal(false)}
            selectedPetData={petData}
            familyNames={globalInfo.familyInfo.name}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default MessageTemplate;
